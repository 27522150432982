.download-app-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 2rem auto;
    background-color: #f4f4f4;

    .border-height {
      height: 100%;
      width: 0px;
    }

    .or-position {
      position: absolute;
      top: 40px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #f4f4f4;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }
  
    .scan-section {
      // background: #f8f9fa;
      padding: 2rem;
      border-radius: 15px;
      width: 100%;
  
      .qr-placeholder {
        background: #fff;
        padding: 1rem;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  
        .qr-image {
          width: 200px;
          height: 200px;
          background: #e9ecef;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          border-radius: 8px;
        }
      }
  
      h2 {
        font-size: 1.5rem;
        color: #333;
        font-weight: 600;
        margin-top: 1.5rem !important;
      }
    }
  
    .phone-section {
      // background: #ffffff;
      padding: 1rem;
      border-radius: 15px;
      width: 100%;
      // box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  
      h3 {
        font-size: 1.25rem;
        color: #333;
        font-weight: 600;
        margin-bottom: 1.5rem;
      }
  
      .form-label {
        font-weight: 500;
        color: #666;
      }
  
      .form-control {
        height: 50px;
        border-radius: 8px;
        border: 1px solid #ced4da;
        padding: 0.75rem 1rem;
  
        &:focus {
          border-color: #80bdff;
          box-shadow: none;
        }
      }
  
      .btn-primary {
        height: 50px;
        border-radius: 8px;
        font-weight: 600;
        background-color: #007bff;
        border: none;
        font-size: 1rem;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  
    @media (max-width: 767px) {
      padding: 1rem;

      .border-height {
        height: 0px;
        width: 100%;
      }

      .or-position {
        top: -20px;
        width: 40px;
        height: 40px;
      }
  
      .scan-section {
        margin-bottom: 2rem;
  
        .qr-placeholder .qr-image {
          width: 200px;
          height: 200px;
        }
      }
  
      .phone-section {
        padding: 1.5rem;
      }
    }
  }
